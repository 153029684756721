<template>
  <div>
    <br>
    <div v-if="loading" class="text-center">
      <div class="la-ball-atom la-2x mx-auto"><div v-for="n in 4" :key="n"></div></div>
    </div>
    <span v-else>
      <table class="table table-bordered">
        <tr class="text-center" v-for="(data, index) in csvData" :key="index">
          <td class="align-middle" :class ="{'font-weight-bold': !index || !index1}" v-for="(data1, index1) in data" :key="index1">
            <span>{{data1}}</span>
          </td>
        </tr>
      </table>
    </span>
    <br>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TableLove',
  props: ['gid', 'url', 'sheets'],
  data () { return {
    csvData: [],
    first_empty: false,
    stop: false,
    loading: true,
  } },
  components: {},
  mounted: function(){
    if (this.gid && this.url){
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}` }
      }
      let vm = this
      let all_empty = false
      const sheetNumber = this.getSheetNumberByGid(this.sheets, this.gid)
      if(!sheetNumber){
        this.loading = false
        this.csvData = [['Error'],['Table not found']]
        return
      }
      const title = this.sheets[sheetNumber].properties.title
      axios.get(
        'https://sheets.googleapis.com/v4/spreadsheets/'+ this.url+ '/values/'+ title+'!A1:ZZ9999', config
        ).then( (res) => {
      for (let i = 0 ; i < res.data.values.length; i++) {
            if (res.data.values
              && res.data.values[i] != "") {
                const record = res.data.values[i]
                all_empty = record.every((r)=>{
                  return r.length===0
                })
                if (all_empty || vm.stop){
                  if (vm.first_empty){
                    vm.stop = true
                  } else {
                    vm.first_empty = true
                  }
                } else {
                  vm.csvData.push(record)
            }
          } 
        }
        vm.loading = false
    })
    .catch((error)=>{
      this.loading = false
      this.csvData = [['Error'],[error.data]]
    })
  }
    },
  methods : {
    getSheetNumberByGid: function(data, gid) {
      for (let i = 0; i < data.length; i++) {
        if (gid == data[i].properties.sheetId) {
          return i
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/loading.scss";

.la-ball-atom  {
  color: $green-font;
  margin: 100px;
}

</style>
