<template>
  <div class="mt-5">
    <b-col>
      
      <div v-if="interventions.length === 0" class="text-center">
        <div class="la-ball-atom la-2x mx-auto"><div v-for="n in 4" :key="n"></div></div>
      </div>
      <div v-else>
        <div class="form-check form-switch ms-2 mb-2">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="showNoIsof">
          <label class="form-check-label" for="flexSwitchCheckDefault">Show interventions without informative evidence available</label>  
        </div>
        <div class="d-none d-xl-block wrapper">
          <table class="ops-table">
            <tr class="sticky-top">
              <th class="outcome"> </th>
              <th v-for="outcome, index in outcomes" :key="index" class="outcome"> <span v-if="outcome == 'Symptom resolution'"> Symptoms resolution  </span> <span v-else> {{outcome}} </span> </th>
            </tr>
            <tr>
              <th class="placebo-first"> Standard of care </th>
              <td v-for="outcome, index2 in outcomes" :key="index2" class="placebo">
                <span v-if="Object.prototype.hasOwnProperty.call(placebo, outcome)">
                  {{placebo[outcome]}} per 1000
                </span>
              </td>
            </tr>
            <tr v-for="intervention, index1 in interventions" :key="index1" :class="!showNoIsof && isofs[intervention] === 'no-isof' ? 'd-none' : ''">
              <th class="intervention">
                <router-link v-if="isofs[intervention] !== 'no-isof'" class="router-link-int" :to="'/isof/'+cleanInterventionLink(intervention)"> {{intervention}} </router-link>
                <b-button v-else  v-b-tooltip.hover.right="'A Summary of Findings table is not provided because the available evidence is not informative'" class="router-link-int text-start bg-transparent border-0" :style="{cursor: 'not-allowed'}">{{intervention}}</b-button>
              </th>
              <td v-for="outcome, index2 in outcomes" :key="index2" :class="getClass(interventionsoutcomes, intervention+outcome)" class="regular-cell py-2">
                <span v-if="Object.prototype.hasOwnProperty.call(interventionsoutcomes, intervention+outcome) && interventionsoutcomes[intervention+outcome].certainity != 'No evidence'">
                  {{interventionsoutcomes[intervention+outcome]['es_ci_patients'] - interventionsoutcomes[intervention+outcome]['placebo_es_patients'] }}
                  ({{interventionsoutcomes[intervention+outcome]['low_ci_patients'] - interventionsoutcomes[intervention+outcome]['placebo_es_patients'] }} to {{interventionsoutcomes[intervention+outcome]['high_ci_patients'] - interventionsoutcomes[intervention+outcome]['placebo_es_patients']}} )
                  <div class="h6 pt-1">
                    {{ interventionsoutcomes[intervention+outcome].n_studies }} <span v-if="interventionsoutcomes[intervention+outcome].n_studies != 1"> studies </span> <span v-else> study </span>
                  </div>
                </span>
              </td>
            </tr>
          </table>
        </div>
        <div class="d-xl-none">
          <MobileBigTable 
            :showNoIsof="showNoIsof" 
            :outcomes="outcomes"
          />
        </div>
      </div>

    </b-col>

  </div>
</template>

<script>
import MobileBigTable from "./MobileBigTable.vue"
export default {
  name: 'BigTable',
  components: {
    MobileBigTable
  },
  data () {
    return {
      outcomes: [
        "Mortality",
        "Mechanical ventilation",
        "Symptom resolution",
        "Infection (Prophylaxis)",
        "Adverse events",
        "Hospitalization"
      ],
      showNoIsof: false,
    }
  },
  computed: {
    interventions () {
      return this.$store.state.interventions
    },
    interventionsoutcomes () {
      return this.$store.state.interventionsoutcomes
    },
    placebo () {
      return this.$store.state.placebo
    },
    isofs () {
      return this.$store.state.isofs
    }
  },
  methods: {
    getClass(obj, objKey) {
      if (Object.prototype.hasOwnProperty.call(obj, objKey)) {
        let interventionOutcome = obj[objKey];
        let io_class = 'no-evidence';
        if (interventionOutcome.certainity == 'No evidence') {
          io_class = 'no-evidence';
        }
        else if (interventionOutcome.certainity == 'Very Low') {
          io_class = 'very-low';
        }
        else if (interventionOutcome.certainity == 'Low') {
          io_class = 'low-' + interventionOutcome.effect;
        }
        else if (interventionOutcome.certainity == 'Moderate') {
          io_class = 'moderate-' + interventionOutcome.effect;
        }
        else if (interventionOutcome.certainity == 'High') {
          io_class = 'moderate-' + interventionOutcome.effect;
        }
        return io_class

      } else {
        return 'no-evidence'
      }
    },
    cleanInterventionLink(int) {
      return int.replace(/\//g, '~');
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/variables.scss";
@import "../assets/styles/loading.scss";

.la-ball-atom  {
  color: $green-font;
  margin: 100px;
}

.no-evidence {
  background-color: #ebebeb;
}

.very-low {
  background-color: #bdbdbd;
}

.low-benefical {
  background-color: #d9ead3;
}

.low-not-significant {
  background-color: #fff2cc;
}

.low-harmful {
  background-color: #f4cccc;
  color: white;
}

.moderate-benefical {
  background-color: #6aa84f;
}

.moderate-not-significant {
  background-color: #ffd966;
}

.moderate-harmful {
  background-color: #cc0000;
  color: white;
}

.placebo {
  background-color: #767171;
  color:white;
  font-weight: 700;
  /* padding: 1.1rem 2rem; */
  position: relative;
  text-align: center;
}
.placebo-first {
  background-color: #767171;
  color:white;
  font-weight: 700;
  text-align: start;
  vertical-align: middle;
  padding: 1.1rem 1.5rem;
  position: sticky;
  position: -webkit-sticky;
}

.outcome {
  background-color: #34aead;
  color:white;
  padding: 1.5rem 1rem;
  text-align: center;
  vertical-align: middle;
  width: 12vw;
  word-wrap:break-word;
  white-space: normal;
}

.intervention {
  color: white;
  background-color:#34aead;
  font-weight: 700;
  text-align: start;
  vertical-align: middle;
  // padding: 1.1rem 1.5rem;
  &:hover {
    background-color: #359D9C;
  }
}

.router-link-int {
  padding: 1.1rem 1.5rem;
  color: inherit;
  text-decoration: none;
  display: inline-block;
  width: 420px;
}

.ops-table {
  margin-bottom: 3rem;
  font-size: 16px;
  max-width: none;
  --bs-table-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #212529;
  border-color: #dee2e6;
  caption-side: bottom;
  border-collapse: separate;
}

.regular-cell {
  font-weight: 550;
  text-align: center;
  position: relative;
}

.symbology-first {
  font-weight: 550;
  text-align: start;
  vertical-align: middle;
  padding: 0.5rem 0.5rem;
  color: #144c4c;
  min-width: 200px;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 5;
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.wrapper {
  position: relative;
  white-space: nowrap;
  @media (max-width: 1325px) {
    max-height: 100%;
    overflow: auto;
  }
  height: 100%;
  max-width: 100%;
  background-color: white;
}
.form-check{
  @media (max-width: 768px) {
    font-size: 14px
  }
  .form-check-input{
    &:focus{
      box-shadow: none;
    }
    &:checked{
    background-color: #34aead;
    border-color: #34aead;
    color: white;
      &:focus{
        border: none;
        box-shadow: none;
      }
    }
  }
}
</style>
