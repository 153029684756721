<template>
  <div class="container static-content">
    <b-row>
      <div>
        <b-col class="">
          <h1 class="">Methods</h1>
          <p>
            We used the Living OVerview of Evidence (L·OVE; <a href="https://iloveevidence.com" target="_blank">https://iloveevidence.com <font-awesome-icon icon="external-link-alt" class=""/></a>) platform to identify studies for inclusion in this review. This platform is a system that maps PICO (Patient– Intervention–Comparison–Outcome) questions to a repository developed by Epistemonikos Foundation. This repository is continuously updated through searches in electronic databases, preprint servers, trial registries, and other resources relevant to COVID-19. The last version of the methods, the total number of sources screened, and a living flow diagram and report of the project is updated regularly on the L·OVE website.
          </p>

          <h2> Search strategy </h2>
          <p>
            We systematically searched in L·OVE for COVID-19. The search terms and databases covered are described on the L·OVE search strategy methods page available at: <a href="https://app.iloveevidence.com/loves/5e6fdb9669c00e4ac072701d?question_domain=undefined&section=methods" target="_blank">https://app.iloveevidence.com/loves/5e6fdb9669c00e4ac072701d?section=methods <font-awesome-icon icon="external-link-alt" class=""/></a>. The repository is continuously updated, and the information is transmitted in real-time to the L·OVE platform, however, it was last checked for this review on 25 January 2022. The searches covered the period from the inception date of each database, and no study design, publication status or language restriction was applied.
          </p>

          <h2>Study selection</h2>
          <p>
            The results of the searches in the individual sources were de-duplicated by an algorithm that compares unique identifiers (database identification number, digital object identifier (DOI), trial registry identification number), and citation details (i.e., author names, journal, year of publication, volume, number, pages, article title, and article abstract). Then, the information matching the search strategy was sent in real-time to the L·OVE platform where at least two authors independently screened the titles and abstracts yielded against the inclusion criteria. We obtained the full reports for all titles that appeared to meet the inclusion criteria or required further analysis and then decided about their inclusion.
          </p>
          <h2>Inclusion criteria</h2>
          <p>
            We aimed to find all available RCTs for potential therapeutic pharmacological interventions for COVID-19 with study designs that included head-to-head comparisons, or control groups with no intervention or a placebo. Target patient populations included both adults and children exposed to or with confirmed or suspected COVID-19. We focused on comparative effectiveness studies that provide evidence on outcomes of crucial importance to patients (mortality, invasive mechanical ventilation, symptom resolution or improvement, infection [prophylaxis studies] and severe adverse events). In addition to RCTs, we included comparative non-RCTs that report on effects of NSAID consumption on mortality. We only incorporated non-RCTs that included at least 100 patients. We presented results of RCTs and non-RCTs separately
          </p>

          <h2>Living evidence synthesis</h2>
          <p>

            An artificial intelligence algorithm deployed in the Coronavirus/COVID-19 topic of the L·OVE platform provides instant notification of articles with a high likelihood of being eligible. The authors review them, decide upon inclusion, and update the living web version of the review accordingly. If meta-analytical pooling is possible from retrieved evidence, we will do this to derive more precise estimates of effect and derive additional statistical power.
          </p>
          <p>
            The focus has been on RCTs studies for all included therapeutic pharmacological interventions (adults and children). Adults and children exposed to or with confirmed or suspected COVID-19 were and will be included. Trials that compare interventions head-to-head or against no intervention or placebo is the focus. We have focused on comparative effectiveness studies that provide evidence on patient-important outcomes (mortality, invasive mechanical ventilation, symptom resolution or improvement, infection (prophylaxis studies), hospitalization (studies that included patients with non-severe disease) and severe adverse events). For studies that assessed thromboprophylactic interventions we also assessed venous thromboembolic events and major bleeding. For the outcome "hospitalization" we included information from studies reporting the number of hospitalizations or the number of hospitalizations combined with the number of deaths without hospitalization. We did not include information from studies reporting a combination of hospitalizations and medical consultations. No electronic database search restrictions were imposed.
          </p>
          <p>
            For any meta-analytical pooling, if and when data allow, we pool all studies and present the combined analysis with relative and absolute effect sizes. To assess interventions' absolute effects, we applied relative effects to baseline risks (risks with no intervention). We extracted mortality and invasive mechanical ventilation baseline risks from the ISARIC cohort as of 18 December 2020. For baseline infection risk in exposed to COVID-19 we used estimates from a SR on physical distancing and mask utilization, and for adverse events and symptom resolution/improvement we used the mean risk in the control groups from included RCTs until 18 December 2020. For venous thromboembolic events and major bleeding baseline risk we used the mean risk in the control groups from included RCTs until 25 March 2021. For hospitalization baseline risk we used the median risk in the control groups from included RCTs until 23 December 2021. We continuously monitor baseline risks by assessing the mean risk of every outcome in the control groups of included RCTs. When substantial changes to baseline risks are detected, we update the estimates used for absolute effects calculations. For mortality, there were some drug instances whereby we provide systematic-review (meta-analysis) evidence indirectly related to patients with COVID-19, e.g., corticosteroids in patients with ARDS.
          </p>
          <p>
            For result interpretations and imprecision assessment we used a minimally contextualized approach which considers whether the 95%CI includes the null effect, or, when the point estimate is close to the null effect, whether the 95%CI lies within the boundaries of small but important benefit and harm that corresponds to every outcome assessed. We used the following thresholds to define important benefits and harms:
          </p>

          <p>
            <b-table small :items="outcomes" responsive="sm" :bordered="false" :borderless="true" class="my-1 outcomes">
              <template #head()="data">
                <span class="d-none">{{data}}</span>
              </template>
            </b-table>
          </p>

          <p>
            For some interventions when we found significant heterogeneity, we performed subgroup analysis considering:
            <ol>
              <li>risk of bias (high/moderate vs low risk of bias)</li>
              <li>disease severity (mild, moderate, severe, or critical)</li>
              <li>intervention's characteristics (i.e., different doses or administration schemes)</li>
            </ol>
            When we observed significant differences between subgroups, we presented individual subgroup's estimates of effect and certainty of the evidence assessment.
          </p>
          <p>
            A risk of bias assessment was applied to RCTs focusing on randomization, allocation concealment, blinding, attrition, or other biases relevant to the estimates of effect (Table 4). For non-RCTs, potential residual confounding was assumed in all cases and certainty of the evidence was downgraded twice for risk of bias. The GRADE approach was used to assess the certainty on the body of evidence for every comparison on an outcome basis (Table 5). Risk of bias judgments were compared against other similar projects (Drug treatments for covid-19: living systematic 39 review and network meta-analysis and The COVID-NMA initiative). Significant discrepancies were discussed until a final decision was reached.
          </p>
          <p>
            We used MAGIC authoring and publication platform <a href="https://app.magicapp.org/" target="_blank">https://app.magicapp.org/ <font-awesome-icon icon="external-link-alt" class=""/></a> to generate the tables summarizing our findings.
          </p>
        </b-col>
      </div>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import jwt from 'jsonwebtoken'

export default {
  name: 'References',
  data() {
    return {
      references: [],
      refFinal: [],
      allRef: [],
      loading: true,
      outcomes: [
        {"outcome": "Mortality", "threshold": "± 1%"},
        {"outcome": "Mechanical ventilation", "threshold": "± 2%"},
        {"outcome": "Symptom resolution or improvement", "threshold": "± 5%"},
        {"outcome": "Symptomatic infection in exposed individuals", "threshold": "± 5%"},
        {"outcome": "Hospitalization in patients with mild recent COVID-19", "threshold": "± 1.9%"},
        {"outcome": "Severe adverse events", "threshold": "± 3%"},

      ]
    }
  },
  mounted: async function(){
    await this.createJwt()
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}` }
      }
      let vm = this
      axios.get(
        'https://sheets.googleapis.com/v4/spreadsheets/1fUX-SgxLhr08YuwH1RoBG7wqT3mmkrwMAdi1MnX8sGE/values/References!A1:AZ9999', config
        ).then ( (res) => {
      for (let i = 0 ; i < res.data.values.length; i++) {
            if (res.data.values
              && res.data.values[i] != "") {
              const record = res.data.values[i]
              let ref = record[3].match(/[\da-fA-F]{40}/gim)
              if (ref) {
                vm.references = [...new Set([...vm.references, ...ref])]
              }
            }
      }
      this.getCitations()
      vm.loading=false
    })
    .catch((error)=>{
      console.log(error)
      this.loading = false;
    })
  },
  methods : {
    getCitations: function() {
      // eslint-disable-next-line no-useless-escape
      const clean_references = this.references.map( (ref) => {return ref.replace(/[\[\]']+/g, '')})
      if (clean_references.length == 0){
        return
      }
      const payload = {"responseType":"blob","references_ids": clean_references}
      const authToken = process.env.VUE_APP_AUTH_TOKEN
      const config = {
        headers: {
          Authorization: `Token session="${authToken}"`
        }
      }
      const url = 'https://api.iloveevidence.com/v2.1/references/get-from-ids?filetype=citation_json'
      axios.post(url,payload, config)
      .then((res) => {
        this.refFinal = res.data.references
        this.allRef = this.references.map((reference) => {
          const auxRefFinal = this.refFinal.map( (ref) => { return ref.id})
          // eslint-disable-next-line no-useless-escape
          let cleanRef = reference.replace(/[\[\]']+/g, '')
          let index = auxRefFinal.indexOf(cleanRef)
          if (index > -1){
            return {
              id: cleanRef,
              citation: this.refFinal[index].citation
            }
          }
          else {
            return {
              id: cleanRef,
              citation: ''
            }
          }
        })
      })
      },
    async createJwt () {
      const secret =  process.env.VUE_APP_SECRET_KEY
      const date = new Date()
      const seconds = Math.floor(date.getTime() / 1000)
      const expSeconds = seconds + 60 * 60
      const payload = {
        iss: "planilla-love@planilla-love.iam.gserviceaccount.com",
        scope: 'https://www.googleapis.com/auth/drive',
        aud: 'https://oauth2.googleapis.com/token',
        exp: expSeconds,
        iat: seconds
      }
      const token = jwt.sign(payload, secret, { algorithm: 'RS256' })
      const oAuthPayload = {
        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
        assertion: token
      }
      await axios.post('https://oauth2.googleapis.com/token', oAuthPayload)
        .then((res) => { localStorage.setItem('gapi.access_token', res.data.access_token) })
        .catch((err) => { console.log(err) })
      return token
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/typography.scss";

.container {
  margin-top: 4em;
  margin-bottom: 4em;
}
.outcomes {
  max-width: 550px;
}

</style>
