<template>
  <div class="container static-content">
    <h1>Terms and conditions</h1>

    <template>
      <p>Use of this Pan American Health Organization (PAHO) Internet site constitutes acceptance of the following Terms and Conditions.</p>
      <p>PAHO reserves the right to institute legal action to stop any infringement or breach of these Terms and Conditions.</p>
      <p>PAHO may, at its sole discretion, modify these Terms and Conditions, and will publish these modifications on this site. Using the PAHO site after these changes take effect constitutes acceptance on the part of the user of the new Terms and Conditions.</p>
      <p>PAHO reserves the exclusive right to cancel any user’s access as a result of infringement of rights or breach of these Terms and Conditions.</p>

      <h2>Use of PAHO’s name</h2>

      <p>PAHO’s name, acronym, and/or logo, as well as its branding, are protected by international law. Except for purposes of attributing authorship, they may not be used without PAHO’s prior express and written authorization. Nor may PAHO’s name, acronym, logo and/or branding be used for promotional purposes or in any form that indicates, suggests, or could be interpreted as representing association or affiliation with PAHO, or the Organization’s sponsorship or backing.</p>

      <h2>Limitation of Liability</h2>
      <p>The data, information, and documents published on this site or sent through it are provided without any type of explicit or implicit guarantee. PAHO does not offer guarantees, nor is it responsible for the accuracy or truth of the information or documents provided herein. PAHO may periodically modify the documents contained on this site without prior notice. Under no circumstance will the Organization be liable for losses, damages and/or harm that may allegedly be derived from the use of this site or its information, data, or documents. Users must utilize this site at their own expense and risk. In no case will PAHO be liable for losses and/or harm even when prior notice could have been given concerning them.</p>
      <p>PAHO reserves the right to modify or discontinue any document that it makes available to the public through this site, with or without prior notice. The use of country or territorial designations must not be regarded as indicative of PAHO’s position in regard to the legal status of any country or territory, its authorities and institutions, or the recognition of any border.</p>

      <p>PAHO will not be liable for any damages or loss that may derive from or be related to the use of or flaws in the site or the information contained herein, including in cases arising from inappropriate, improper, or fraudulent use. Nor will it be responsible for the accuracy, availability, or truth of the information contained herein.</p>
      <p>PAHO does not offer guarantees of any kind concerning the information available on the site and will not be responsible for any computer-virus infection or any other program that may affect the functioning of computers or systems.</p>
      <p>Some of the documents that the user can access through this site may belong to third parties; PAHO does not guarantee their ownership and will not be responsible to the user or third parties for any claim deriving from their use. It is possible that the site or the documents may contain links to external sites not managed by PAHO. Such links are included for reference purposes and their inclusion does not imply PAHO’s approval or endorsement. Nor does PAHO assume responsibility for the information contained on such external sites.</p>
      <p>Use of this site implies the user’s acceptance of the obligation to defend and indemnify PAHO and its affiliates for any action, claim of damages, loss and/or expenditure (including attorney fees) that may derive from such use.</p>
      <h2>Privileges and Immunities</h2>

      <p>Nothing set forth herein will constitute or may be interpreted as a renunciation, express or tacit, of the privileges, immunities, and exemptions that PAHO enjoys in conformity with International Law, treaties or international agreements, or the legislation of its Member Countries.</p>

      <h2>Discussion forums</h2>

      <p>PAHO may offer discussion forums or other means of communication; the user, on making use of these instruments, agrees not to:
        <ol type="a">
          <li>Slander, injure, harass, threaten, or engage in any other type of conduct that violates others’ rights;</li>
          <li>Circulate, publish, distribute, or disseminate information or materials that are libelous, obscene, indecent, unlawful, or immoral; </li>
          <li>Upload to the site or attach to a message, files that contain computer programs or other materials protected by intellectual property laws (or by rights of privacy and publicity), without having proper authorization;</li>
          <li>Fail to include the reference to the author or legal notices; to deliberately misstate the origin of, or present as one’s own, computer programs or other materials contained in a file uploaded to the site;</li>
          <li>Announce goods or services or offer them for sale; conduct or promote surveys, competitions, or chain letters; or download from a forum a file posted by another user which the user knows, or reasonably should know, cannot be legally distributed in this manner.</li>
        </ol>
      </p>
      <p>The user recognizes that all discussion forums constitute a means of public, rather than private, communication. Furthermore, the user recognizes that the dialogues and documents published on the site do not have PAHO’s backing and will not be considered as revised, examined, or approved by PAHO. </p>
      <h2>Complaints for possible infractions </h2>
      <p>PAHO will make an effort to prevent activities on its site that are unlawful and contrary to the Terms and Conditions contained herein. Nevertheless, if you believe that an item of information contained on the site or among its materials may be contravening your rights or those of third parties, including, without limitation, intellectual property rights, you must contact PAHO, sending a detailed description of the alleged violation to the following address:</p>
      <p>
        Pan American Health Organization
        525 23rd Street N.W.
        Washington, D.C. 20037
        U.S.A.
      </p>
      <h2>Resolution of disputes</h2>
      <p>Any dispute, controversy, or complaint that may arise or be derived from the use of the site or its information shall be resolved in an amicable manner between PAHO and the user. Unless the disputes or controversies are resolved in an amicable manner within sixty (60) days after one of the parties receives the other’s request to attempt to reach an amicable resolution, such dispute, controversy, or claim will be sent by either of the parties to arbitration in accordance with the Arbitration Rules of the United Nations Commission on International Trade Law (UNCITRAL) in effect. The arbitral tribunal will not be authorized to impose punitive damages. Any arbitral award made in virtue of the aforementioned arbitration will be considered the final decision on the dispute, controversy, or claim, and will be binding on the parties. </p>

      <p><strong>THIS SITE DOES NOT PROVIDE MEDICAL ADVICE</strong></p>
      <p><strong>The content of the site, including, but not limited to, the text, graphics, images, links, and other materials are for informational purposes only.  The content is not intended to be a substitute for professional medical advice, diagnosis, or treatment, and does not constitute medical or other professional advice.  PAHO does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned on the site.  Reliance on any information provided herein is solely at your own risk.</strong></p>
      <p><strong>If you do not agree with any of the terms and conditions contained herein, you must not use this site.</strong></p>
    </template>
    
  </div>
</template>

<script>

export default {
  name: 'TermsConditions'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/typography.scss";
// h2 {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
//   padding: 5px;
// }
// p {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
// }
// img {
//   background-color: $white;
// }
</style>
