<template>
  <div class="container static-content">
    <div v-if="loading" class="text-center">
      <div class="la-ball-atom la-2x mx-auto"><div v-for="n in 4" :key="n"></div></div>
    </div>

    <div class="lsr-container" v-else>
      <div v-if="mode">
        <div v-for="(data,index) in csvData" :key=index>
          <h1 v-if="data[0]==='t1'"> {{data[language[selectedLanguage]]}} </h1>
          <h2 v-if="data[0]==='t2'" :id="'id'+index.toString()"> {{data[language[selectedLanguage]]}} </h2>
          <h3 v-if="data[0]==='t3'"> {{data[language[selectedLanguage]]}} </h3>
          <h4 v-if="data[0]==='t4'"> {{data[language[selectedLanguage]]}} </h4>
          <h5 v-if="data[0]==='t5'"> {{data[language[selectedLanguage]]}} </h5>
          <p v-if="data[0]==='p'"> {{data[language[selectedLanguage]]}} </p>
          <table-lovely v-if="data[0]==='table'" :gid="data[1]" :url="url" :sheets="sheets" />
          <list v-if="data[0]==='ul' || data[0]==='ol'" :lists="lists" :index="data[1]" :language="language[selectedLanguage]" />
          <img class="image" v-if="data[0]==='img'" :src="data[1]" />
          <iframe 
            v-if="data[0]==='isof'" 
            class="my-4"
            :src="parseISof(data[1])" 
            v-resize="{ log: false }"
            width="100%"
            frameborder="0"
          />
          <br v-if="data[0]===''">
        </div>
      </div>
      <div v-else>
        <p> {{data[0]}} </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TableLove from '@/components/Table.vue'
import Lists from '@/components/Lists.vue'
import axios from 'axios'
import jwt from 'jsonwebtoken'

export default {
  name: 'Tab',
  data() {
    return {
      csvData : [],
      loading: true,
      lists: [],
      lists_counter: 0,
      references: [],
      refFinal: [],
      allRef: [],
      language: {
        "En": 1,
        "Es": 2,
      },
      selectedLanguage: "En",
    }
  },
  components: {
    'table-lovely': TableLove,
    'list': Lists,
  },
  computed: {
    mode: function(){
      if (this.csvData.length){
        return this.csvData[0].length > 1
      } else {
        return false
      }

    }
  },
  mounted: async function(){
      await this.createJwt()
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}` }
      }
      let vm = this
      let createList = false
      let type = ""
      axios.get(
        'https://sheets.googleapis.com/v4/spreadsheets/1fUX-SgxLhr08YuwH1RoBG7wqT3mmkrwMAdi1MnX8sGE/values/Changelog!A1:AZ9999', config
        ).then ( (res) => {
         if (!Object.prototype.hasOwnProperty.call(res.data, 'values')) {
           vm.loading = false
           vm.csvData = [['t1','']]
           return
         }
      for (let i = 0 ; i < res.data.values.length; i++) {
            if (res.data.values
              && res.data.values[i] != "") {
              const record = res.data.values[i]
              if (createList && (record[0]=== type)) {
                vm.lists[vm.lists_counter].elements.push(record)
              }
              else if (createList && !(record[0]===type)) {
                vm.lists_counter+=1
                if ((record[0]==='ul' || record[0]==='ol')) {
                  type = record[0]
                  vm.csvData.push([record[0], vm.lists_counter])
                  vm.lists.push({type: record[0], elements: []})
                  vm.lists[vm.lists_counter].elements.push(record)
                } else {
                  createList = false
                  vm.csvData.push(record)
                }
              } 
              else if (!createList && (record[0]==='ul' || record[0]==='ol')) {
                type = record[0]
                createList = true
                vm.csvData.push([record[0], vm.lists_counter])
                vm.lists.push({type: record[0], elements: []})
                vm.lists[vm.lists_counter].elements.push(record)
              } else {
                vm.csvData.push(record)
              }
            }
      }
      vm.loading=false
    })
    .catch((error)=>{
      console.log(error)
      this.loading = false;
      this.csvData = [['t1','Error'],['t3',error.data]]
    })
  },
  methods : {
    getSheetNumberByGid: function(data, gid) {
      for (let i = 0; i < data.length; i++) {
        if (gid == data[i].properties.sheetId) {
          return i
        }
      }
    },
    parseISof: function(isof) {
      if (isof) {
        if (isof.includes("https://isof.epistemonikos.org/#/exportable_table/")) {
        return isof
      }
      else {
        return 'https://isof.epistemonikos.org/#/exportable_table/' + isof
      }
      } else{
        return ""
      }
    },
    async createJwt () {
      const secret =  process.env.VUE_APP_SECRET_KEY
      const date = new Date()
      const seconds = Math.floor(date.getTime() / 1000)
      const expSeconds = seconds + 60 * 60
      const payload = {
        iss: "planilla-love@planilla-love.iam.gserviceaccount.com",
        scope: 'https://www.googleapis.com/auth/drive',
        aud: 'https://oauth2.googleapis.com/token',
        exp: expSeconds,
        iat: seconds
      }
      const token = jwt.sign(payload, secret, { algorithm: 'RS256' })
      const oAuthPayload = {
        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
        assertion: token
      }
      await axios.post('https://oauth2.googleapis.com/token', oAuthPayload)
        .then((res) =>  { localStorage.setItem('gapi.access_token', res.data.access_token) })
        .catch((err) => { console.log(err) })
      return token
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/variables.scss";
  @import "../assets/styles/loading.scss";
  @import "../assets/styles/typography.scss";

  .la-ball-atom  {
    color: $green-font;
    margin: 100px;
  }
  .lsr-container {
    margin-top: 4em;
    margin-bottom: 4em;

    .header {
      margin-bottom: 2em;
      h1 {
        font-weight: bold;
        margin-top: 0.3em;
        margin-bottom: 1em;
      }
      .publication-type {
        font-weight: bold;
        font-size: 0.9em;
      }
      .authors, .dates {

      }
    }

    .tab_content {
      .languages {
        margin-bottom: 2em;
      }
      h1:not(:first-child) {
        margin-top: 1.8em;
      }
      h2 {
        font-weight: bold;
        &:not(:first-child) {
          margin-top: 1.4em;
        }
      }
      h3 {
        font-style: italic;
        &:not(:first-child) {
          margin-top: 1.2em;
        }
      }
      ul.arrow {
        margin-inline-start: 1.5em;
        // li:before {
        //   font-family: 'FontAwesome';
        //   content: '\f061'
        // }
      }
    }

    .image {
      max-width: 100%;
    }
    .isof {
      min-width: 801px;
      min-height: 600px;
    }
  }
</style>

<style>
html {
  scroll-behavior: smooth;
}

</style>
