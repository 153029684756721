<template>
  <div class="container">
    <b-row >
      <b-col class="">
        <h1 class="title">Included Studies</h1>

        <div v-if="ui.loading" class="text-center">
          <div class="la-ball-atom la-2x mx-auto"><div v-for="n in 4" :key="n"></div></div>
        </div>


        <ol v-else class="references">
          <li v-for ="reference in allRef" :key="'ref_' + reference.id">
            {{reference.citation}} <span class="doi-link" v-if="reference.doi"><a :href="'https://doi.org/' + reference.doi" target="_blank">{{reference.doi}} <font-awesome-icon icon="external-link-alt" class=""/></a></span>
          </li>
        </ol>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import jwt from 'jsonwebtoken'


export default {
  name: 'References',
  components: {
  },
  data() {
    return {
      references: [],
      refFinal: [],
      allRef: [],
      ui: {
        error: false,
        loading: true
      }
    }
  },
  mounted: async function(){
    await this.createJwt()
      this.$store.dispatch('readReferences').then ( () => {
      this.references = this.$store.state.references
      this.getCitations()
      // this.loading=false
    })
    .catch((error)=>{
      console.log(error)
      this.ui.loading = false;
      this.ui.error = true;
    })
  },
  methods : {
    getCitations: function() {
      // eslint-disable-next-line no-useless-escape
      const clean_references = this.references.map( (ref) => {return ref.replace(/[\[\]']+/g, '')})
      if (clean_references.length == 0){
        return
      }
      const payload = {"responseType":"blob","references_ids": clean_references}
      const authToken = process.env.VUE_APP_AUTH_TOKEN
      const config = {
        headers: {
          Authorization: `Token session="${authToken}"`
        }
      }
      const url = process.env.VUE_APP_API_URL  + '/references/get-from-ids?filetype=citation_json_doi'
      axios.post(url,payload, config)
      .then((res) => {
        this.refFinal = res.data.references
        this.allRef = this.references.map((reference) => {
          const auxRefFinal = this.refFinal.map( (ref) => { return ref.id})
          // eslint-disable-next-line no-useless-escape
          let cleanRef = reference.replace(/[\[\]']+/g, '')
          let index = auxRefFinal.indexOf(cleanRef)
          if (index > -1){
            return {
              id: cleanRef,
              citation: this.refFinal[index].citation,
              doi: this.refFinal[index].doi
            }
          }
          else {
            return {
              id: cleanRef,
              citation: '',
              doi: ''
            }
          }
        })
          this.ui.loading = false;
          this.ui.error = true;
      })
      .catch((error) => {
        console.log(error)
        this.ui.loading = false
        this.ui.error = true
      })
    },
    async createJwt () {
      const secret =  process.env.VUE_APP_SECRET_KEY
      const date = new Date()
      const seconds = Math.floor(date.getTime() / 1000)
      const expSeconds = seconds + 60 * 60
      const payload = {
        iss: "planilla-love@planilla-love.iam.gserviceaccount.com",
        scope: 'https://www.googleapis.com/auth/drive',
        aud: 'https://oauth2.googleapis.com/token',
        exp: expSeconds,
        iat: seconds
      }
      const token = jwt.sign(payload, secret, { algorithm: 'RS256' })
      const oAuthPayload = {
        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
        assertion: token
      }
      await axios.post('https://oauth2.googleapis.com/token', oAuthPayload)
        .then((res) => { localStorage.setItem('gapi.access_token', res.data.access_token) })
        .catch((err) => { console.log(err) })
      return token
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/loading.scss";
@import "../assets/styles/typography.scss";

.doi-link {
    margin-left: 1em;
}
.la-ball-atom  {
  color: $green-font;
  margin: 100px;
}
.container {
  margin-top: 4em;
  margin-bottom: 4em;
}
</style>
