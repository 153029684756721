<template>
  <div class="row">
    <div class="col-12 text-center" v-if="loading">
      <div class="la-ball-atom la-2x mx-auto"><div v-for="n in 4" :key="n"></div></div>
    </div>

    <div class="col-12" v-else>
      <table>
        <tr>
          <th class="symbology-first"> </th>
          <th class="symbology-first"> High/ moderate certainty </th>
          <th class="symbology-first"> Low certainty</th>
        </tr>
        <tr>
          <th class="symbology-first"> More effective than standard of care </th>
          <td class="moderate-benefical" > </td>
          <td class="low-benefical" > </td>
        </tr>
        <tr>
          <th class="symbology-first"> Not convincingly different than standard of care </th>
          <td class="moderate-not-significant" >  </td>
          <td class="low-not-significant" >  </td>
        </tr>
        <tr>
          <th class="symbology-first"> More harmful than standard of care</th>
          <td class="moderate-harmful" > </td>
          <td class="low-harmful" >  </td>
        </tr>
        <tr>
          <th class="symbology-first"> Very low certainty </th>
          <td class="very-low" colspan="2"> </td>
        </tr>
        <tr>
          <th class="symbology-first"> No Evidence or no estimable effect </th>
          <td class="no-evidence" colspan="2"> </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Symbology',
  data () {
    return {
      interventions: [],
      outcomes: [],
      interventionsoutcomes: {},
      placebo: {},
      loading: false
    }
  },
   mounted: async function() {},
  methods: {
    getClass(obj, objKey) {
      if (Object.prototype.hasOwnProperty.call(obj, objKey)) {
        return obj[objKey].class
      } else {
        return 'no-evidence'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/loading.scss";
@import "../assets/styles/variables.scss";

.la-ball-atom  {
  color: $green-font;
  margin: 100px;
}
.no-evidence {
  background-color: #ebebeb;
}

.very-low {
  background-color: #bdbdbd;
}

.low-benefical {
  background-color: #d9ead3;
}

.low-not-significant {
  background-color: #fff2cc;
}

.low-harmful {
  background-color: #f4cccc;
  color: white;
}

.moderate-benefical {
  background-color: #6aa84f;
}

.moderate-not-significant {
  background-color: #ffd966;
}

.moderate-harmful {
  background-color: #cc0000;
  color: white;
}

.symbology-first {
  font-weight: 550;
  text-align: start;
  vertical-align: middle;
  padding: 0.5rem 0.5rem;
  color: #144c4c;
  width: 33vw;
  @media (max-width:768px) {
    font-size: 10px;
  }
}
</style>
