<template>
  <div class="container static-content">
    <b-row >
      <div>
        <b-col class="">
            
          <h1 class="title">About</h1>
          <p>
            The COVID-19 therapeutic options summary of evidence aims to summarize the body of evidence on pharmacological interventions for the management of COVID-19. The resource Is freely accessible and supported in a living systematic review of the evidence periodically published in the <a href="https://iris.paho.org/handle/10665.2/52719" target="_blank"> Iris PAHO Repository <font-awesome-icon icon="external-link-alt" class=""/></a>. The information is summarized and presented following the GRADE approach including interactive summary of findings tables (<a href="https://isof.epistemonikos.org/" target="_blank">iSoF <font-awesome-icon icon="external-link-alt" class=""/></a>) and code-colored estimates of effects summaries. Its primary targets is to support decision makers and reviewers considering alternatives for the management of COVID-19.
          </p>
          <p>
            This platform will feed on continued input from users and, thus, it’s functions will
            continue to improve and it will be updated regularly to maintain relevance.
          </p>
          <p>
            It is a product of the collaboration between the <a href="https://www.paho.org/en" target="_blank">Pan-American Health Organization <font-awesome-icon icon="external-link-alt" class=""/></a> and
            <a href="https://foundation.epistemonikos.org/" target="_blank">Epistemonikos Foundation <font-awesome-icon icon="external-link-alt" class=""/></a> available at the Virtual Health Library (<a href="https://bvsalud.org/en/" target="_blank">VHL<font-awesome-icon icon="external-link-alt" class="ml-1"/></a>).
          </p>
            
          <h1 class="title">Disclaimer</h1>
          <p>
            We do not take responsibility for the accuracy or completeness of the content contained within this website. Although reasonable efforts and care have been applied in its preparation, we assume no liability or responsibility for errors or omissions and consulting the original source of information may be required. Recommendations may be updated or changed as the research evidence and guideline literature accumulate. The information provided within this website is not intended as a substitute for professional advice.
          </p>

        </b-col>
      </div>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'References',
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/variables.scss";
  @import "../assets/styles/typography.scss";

</style>
