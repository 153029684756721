<template>
  <div>
    <div v-if="loading" class="text-center">
      <div class="la-ball-atom la-2x mx-auto"><div v-for="n in 4" :key="n"></div></div>
    </div>
    <b-container class="static-content mt-5 pt-5 pb-5">
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-end mb-4">
            <h1 class="title my-0 py-0"> Bottom line </h1>
            <div class="">
              <router-link to="/summary"> <span class="leftarrow"> ← </span> <span class="back-text"> Back to table </span> </router-link>
            </div>
          </div>
          <p v-if="bottom_lines[selectedIntervention] != ''">{{bottom_lines[selectedIntervention]}}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="this.isofs[this.selectedIntervention]">
          <iframe
            v-if="$mq == 'mobile'"
            class="my-4"
            :src="'https://m-isof.epistemonikos.org/#/finding/'+ this.isofs[this.selectedIntervention]"
            width="100%"
            height="450px"
            frameborder="0"
            v-resize="{ log: false }"
          ></iframe>
          <iframe
            v-else
            class="my-4 w-100 p-4"
            :src="'https://isof.epistemonikos.org/#/exportable_table/' + this.isofs[this.selectedIntervention] + '?no_scroll=true'"
            frameborder="0"
            v-resize="{ log: false }"
          ></iframe>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'SingularIsof',
  data () {
    return {
      interventions: [],
      isofIntervention: null,
      isofs: {},
      bottom_lines: {},
      loading: true,
      ui: {
        isofLoaded: false
      },
      selectedIntervention: null
    }
  },
  props: ['selectedDirtyIntervention'],
   mounted: async function() {
    await this.$store.dispatch('readSpreadsheet')
      .then(
        () => {
          this.interventions = this.$store.state.interventions
          this.isofs = this.$store.state.isofs
          this.bottom_lines = this.$store.state.bottom_lines
          if (this.selectedDirtyIntervention) {
            this.cleandIntevention()
          }
          this.loading = false
        }
      )
      .catch(() => {
        this.loading = false
      })
  },
  methods: {
    cleandIntevention() {
      this.selectedIntervention = this.selectedDirtyIntervention.replace('~','/')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/variables.scss";
@import "../assets/styles/loading.scss";
@import "../assets/styles/typography.scss";

.la-ball-atom  {
  color: $green-font;
  margin: 100px;
}
.leftarrow{
  text-decoration: none;
  color: $lsr-green;
  font-weight: bold;
  text-align: right;
}

.back-text {
  text-decoration: none;
  color: black;
  font-weight: bold;
  text-align: right;
}

</style>
