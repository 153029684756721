<template>
  <div class="container mx-5 px-0 mx-auto mobile-ops-table">
    <table>
      <thead class="">
        <tr class="">
          <th class="p-2"> </th>
          <th v-for="outcome, index in outcomes" :key="index" class="p-2"> <span v-if="outcome == 'Symptom resolution'"> Symptoms resolution  </span> <span v-else> {{outcome}} </span> </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="ps-2 py-3 placebo "> Standard of care </th>
          <td v-for="outcome, index2 in outcomes" :key="index2" class="placebo">
            <span v-if="Object.prototype.hasOwnProperty.call(placebo, outcome)">
              {{placebo[outcome]}} per 1000
            </span>
          </td>
        </tr>
        <tr v-for="intervention, index1 in interventions" :key="index1" :class="!showNoIsof && isofs[intervention] === 'no-isof' ? 'd-none' : ''">
          <th class="ps-2">
            <router-link v-if="isofs[intervention] !== 'no-isof'" :to="'/isof/'+cleanInterventionLink(intervention)"> {{intervention}} </router-link>
            <b-button v-else  v-b-tooltip.hover.right="'A Summary of Findings table is not provided because the available evidence is not informative'" class="text-start bg-transparent border-0" :style="{cursor: 'not-allowed'}">{{intervention}}</b-button>
          </th>
          <td v-for="outcome, index2 in outcomes" :key="index2" :class="getClass(interventionsoutcomes, intervention+outcome)" class="py-1">
            <span v-if="Object.prototype.hasOwnProperty.call(interventionsoutcomes, intervention+outcome) && interventionsoutcomes[intervention+outcome].certainity != 'No evidence'">
              {{interventionsoutcomes[intervention+outcome]['es_ci_patients'] - interventionsoutcomes[intervention+outcome]['placebo_es_patients'] }}
              ({{interventionsoutcomes[intervention+outcome]['low_ci_patients'] - interventionsoutcomes[intervention+outcome]['placebo_es_patients'] }} to {{interventionsoutcomes[intervention+outcome]['high_ci_patients'] - interventionsoutcomes[intervention+outcome]['placebo_es_patients']}} )
              <div class="h6 pt-1">
                {{ interventionsoutcomes[intervention+outcome].n_studies }} <span v-if="interventionsoutcomes[intervention+outcome].n_studies != 1"> studies </span> <span v-else> study </span>
              </div>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'MobileBigTable',
  props: {
    showNoIsof: {
      type: Boolean,
      default: false
    },
    outcomes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    interventions () {
      return this.$store.state.interventions
    },
    interventionsoutcomes () {
      return this.$store.state.interventionsoutcomes
    },
    placebo () {
      return this.$store.state.placebo
    },
    isofs () {
      return this.$store.state.isofs
    }
  },
  methods: {
    getClass(obj, objKey) {
      if (Object.prototype.hasOwnProperty.call(obj, objKey)) {
        let interventionOutcome = obj[objKey];
        let io_class = 'no-evidence';
        if (interventionOutcome.certainity == 'No evidence') {
          io_class = 'no-evidence';
        }
        else if (interventionOutcome.certainity == 'Very Low') {
          io_class = 'very-low';
        }
        else if (interventionOutcome.certainity == 'Low') {
          io_class = 'low-' + interventionOutcome.effect;
        }
        else if (interventionOutcome.certainity == 'Moderate') {
          io_class = 'moderate-' + interventionOutcome.effect;
        }
        else if (interventionOutcome.certainity == 'High') {
          io_class = 'moderate-' + interventionOutcome.effect;
        }
        return io_class

      } else {
        return 'no-evidence'
      }
    },
    cleanInterventionLink(int) {
      return int.replace(/\//g, '~');
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/variables.scss";
@import "../assets/styles/loading.scss";
*{
  font-size:12px
}
.no-evidence {
  background-color: #ebebeb;
}

.very-low {
  background-color: #bdbdbd;
}

.low-benefical {
  background-color: #d9ead3;
}

.low-not-significant {
  background-color: #fff2cc;
}

.low-harmful {
  background-color: #f4cccc;
  color: white;
}

.moderate-benefical {
  background-color: #6aa84f;
}

.moderate-not-significant {
  background-color: #ffd966;
}

.moderate-harmful {
  background-color: #cc0000;
  color: white;
}

.placebo {
  background-color: #767171;
  color:white;
  font-weight: 700;
  text-align: center;
}
.placebo-first {
  background-color: #767171;
  color:white;
  font-weight: 700;
  text-align: start;
  vertical-align: middle;
  padding: 1.1rem 1.5rem;
}

.mobile-ops-table {
  height: 80vh;
  max-width: 100%;
  overflow: auto;
  table {
    white-space: normal;
    table-layout: auto;
    border-collapse: separate;
    thead {
      th {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #34aead;
        color:white;
        text-align: center;
        vertical-align: middle;
        // word-wrap:break-word;
        white-space: normal;
      }
      th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
      }
    }
    tbody {
      tr:first-child{
        th{
           background-color: #767171;
           color:white;
           font-weight: 700;
          }
      }
      th {
        position: sticky;
        left: 0;
        z-index: 1;
        color: white;
        background-color:#34aead;
        font-weight: 700;
        text-align: start;
        vertical-align: middle;
        // padding: 1.1rem 1.5rem;
        &:hover {
          background-color: #359D9C;
        }
        a{
          color: inherit;
          text-decoration: none;
        }
      }
      td {
        white-space: nowrap;
        font-weight: 550;
        text-align: center;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar:horizontal {
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: #999;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}
</style>
