<template>
  <div class="container">
    <b-row>
      <b-col>
        <h1 class="title">Efficacy and safety</h1>

        <h2 class="symbol-collapse py-2 px-4 mt-4" @click="visible = !visible"> How to read this table <span class="arrow" :class="{'open' : visible}"> ▲ </span></h2>

        <b-collapse class="symbol p-4" v-model="visible" role="tabpanel" >
          <Symbology />
        </b-collapse>
        <div class="mb-4">
          <BigTable />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BigTable from '@/components/BigTable.vue'
import Symbology from '@/components/Symbology.vue'

export default {
  name: 'BigTables',
  components: {
    BigTable,
    Symbology,
  },
  data() {
   return {
     visible: true
   }
  }
}
</script>

<style lang="scss">
@import "../assets/styles/variables.scss";
.symbol-collapse {
  color: $ops_green;
  background-color: #ececec;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 5px #ececec solid ;
  margin: 0px;
  cursor: pointer;
   position: relative;
}

.symbol {
  border: 5px #ececec solid ;
}
</style>
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/typography.scss";
.container {
  margin-top: 4em;
  margin-bottom: 4em;
}

.title {
  color: $ops_green;
}


.arrow {
  position: absolute;
  right: 3%;
  text-align: right;
  transform: rotate(0deg);
  transition: all 0.6s ease;
}
.open {
    transform: rotate(180deg);
  }
</style>
