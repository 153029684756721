<template>
  <div>
    <br>
    <ul v-if="lists[index].type =='ul'">
      <li class="list-element" v-for="(element, listIndex) in lists[index].elements" :key="listIndex"> 
        <span> {{element[language]}} </span>
      </li>
    </ul>
    <ol v-if="lists[index].type =='ol'">
      <li class="list-element" v-for="(element, listIndex) in lists[index].elements" :key="listIndex"> 
        <span> {{element[language]}} </span>
      </li>
    </ol>
    <br>
  </div>
</template>

<script>

export default {
  name: 'Lists',
  props: ['lists', 'index', 'language'],
  components: {}
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";

.light-gray-1 {
  background-color: #d9d9d9;
}

.list-element {
  color: darkgreen;
}

.list-element span {
  color: black;
}


</style>
