<template>
  <div class="container static-content">

    <template>
      <h1>BIREME/PAHO'S WEBSITES AND SYSTEMS PRIVACY POLICY</h1>

      <p>The use of products, services and/or tools that the Centro Latinoamericano y del Caribe de Información en Ciencias de la Salud ("BIREME") a specialized center of the Pan American Health Organization, Regional Office for the Americas of the World Health Organization (hereinafter referred to as "BIREME/PAHO") makes available to users constitutes acceptance of the following Privacy Policy. In case of conflict between this text of the Privacy Policy and the text in Spanish, the latter shall prevail.</p>

      <p>This Privacy Policy is complemented by <a href="https://www.paho.org/hq/index.php?option=com_content&view=article&id=3201:paho-website-privacy-policy&Itemid=2410&lang=en" target="_blank">PAHO'S Website Privacy Policy <font-awesome-icon icon="external-link-alt" class="ml-1"/></a>.</p>

      <h1>PRIVACY</h1>
      <p>Your privacy is of the utmost importance to BIREME/PAHO. This Privacy Policy describes BIREME/PAHO'S practice regarding the collection and exchange of user information while using BIREME/PAHO'S websites.</p>

      <h2>What kind of information does BIREME/PAHO collect?</h2>
      <h3>Use of Website</h3>

      <p>In general, you can consult the BIREME/PAHO websites without saying who you are. The information compiled during a general query comes from the server's ordinary records and includes your IP (internet protocol) address, domain name, browser type, operating system and information about how it reached us, the files you downloaded, the websites you have consulted and the date and time of those consultations. Demographic data may also be collected during a query if you are authenticated to a third-party service with permission to share your age, gender and interest category information.</p>

      <h3>Collection of information that allows identifying a person</h3>

      <p>If you register to access a product or service, such as My VHL Personalized Services and FI-Admin, at a BIREME/PAHO website, you will be asked to provide personal information such as your name, email address, institution to which you belong, country of origin, academic level, work field, sex, date of birth and social network data. This information is collected only with your knowledge and permission, and is maintained in BIREME/PAHO databases and lists of addresses.</p>

      <p>For certain BIREME/PAHO websites, registering or providing certain personal information causes a cookie* to be installed on your computer, which will allow BIREME/PAHO to remember your information the next time you access the website, so that you do not have to reenter it, and it helps us to provide you with a better service.</p>

      <p>If you join an electronic discussion group, other participants in the group (including people outside PAHO) may have access to the personal information you have provided. In the case of open discussion groups, such information will be public.</p>

      <h2>What does BIREME/PAHO do with the information it collects?</h2>

      <p>The information collected during a general consultation is used to analyze trends and usage of the BIREME/PAHO websites, as well as to improve the usefulness of those websites.</p>

      <h3>Collection of information that allows identifying a person</h3>

      <p>BIREME/PAHO may use personal information provided by you, among others, to:</p>

      <ul>
        <li>Contact you: either to respond a query or suggestion, or to send you information bulletins, documents, publications, etc.;</li>
        <li>Confirm you registration information provided in the website;</li>
        <li>"remember" your profile and online preferences;</li>
        <li>Help you to find useful information quickly according to your interests and help us to create content that is more useful;</li>
        <li>Perform statistical analyses.</li>
      </ul>

      <h2>What happens if I do not want to provide personal information?</h2>

      <p>Providing personal information on BIREME/PAHO websites is voluntary. If you choose not to provide personal information, you can still consult and use BIREME/PAHO'S websites, but you will not be able to use certain options such as accessing the My VHL Personalized Services or managing BVS information sources through the Web FI-Admin system.</p>

      <h3>Option to modify information or request to have it deleted</h3>

      <p>At any time, you may modify your information or request its deletion: you must enter the site where you first provided the information and communicate with the focal point of that site. If no contact information is provided on the site, you can contact bir.online@paho.org</p>

      <h1>SECURITY</h1>
      <p>All information provided to BIREME/PAHO by users of BIREME/PAHO websites is kept with great care and security, and will not be used in a manner different from those established by this privacy policy, in any of the website's own policies, or those in which you explicitly authorized.</p>

      <p>All our employees who have access to personal data and relate to handling such information are obliged to respect the confidentiality of official matters, including personal data.</p>

      <p>BIREME/PAHO'S websites contain links to external sites. BIREME/PAHO is not responsible for privacy practices or content in such websites.</p>

      <h2>Notification of modifications to the Privacy Policy</h2>

      <p>BIREME/PAHO may modify this Privacy Policy at its sole discretion and will publish such modifications in this website.</p>

      <h2>Contact</h2>
      <p>For questions or inquiries regarding this privacy policy, please contact us at bir.online@paho.org</p>

      <p>* Cookie</p>

      <p>A cookie is a small set of data that is sent from a web server to your browser. It is commonly used to assign a unique identification to your computer and securely store information such as user ID, passwords, preferences and electronic profiles. It is stored on your computer'S hard drive. You have the option of not allowing PAHO/WHO cookies to be installed if you change your browser settings. Different websites can send their own cookies to your computer. To protect your privacy, the browser only allows a website to gain access to its own cookies and does not allow access to those installed by other websites.</p>

      <strong>IF YOU DO NOT AGREE WITH ANY OF THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY YOU MUST REFRAIN FROM USING THE WEBSITE AND ANY PRODUCT, SERVICE AND/OR TOOL CONTAINED HEREIN.</strong>
    </template>

  </div>

</template>

<script>

export default {
  name: 'References'
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/typography.scss";
// h2 {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
//   padding: 5px;
// }
// p {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
// }
// img {
//   background-color: $white;
// }
</style>
