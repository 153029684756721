<template>
  <div class="container">
    <b-row >
      <div>
        <b-col class="mt-4">
          <h1 class="title"> Disclaimer </h1>
          <div>


            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum aliquet, orci interdum porta interdum, diam mi iaculis libero, interdum dapibus purus tortor at risus. Praesent tristique euismod ipsum ut ultricies. Vestibulum non pretium velit. Duis hendrerit cursus vestibulum. Aenean non ipsum ac ex pellentesque pharetra. Pellentesque ultrices magna consequat odio convallis, dignissim molestie nisl hendrerit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nunc turpis, interdum in nisi vel, accumsan euismod nibh. Nam blandit id mi eu placerat. Sed eleifend finibus quam eget ullamcorper. Vestibulum in sapien quam.

            Fusce sit amet finibus mi. Duis molestie nisi imperdiet arcu suscipit, quis vestibulum arcu commodo. Integer suscipit nulla augue, ut ullamcorper leo tempus ac. Vestibulum non lectus suscipit, sodales libero vel, volutpat odio. Quisque malesuada sodales ullamcorper. Aenean in quam congue, sodales orci pulvinar, sagittis mauris. Maecenas eu nibh ac libero feugiat lobortis non vel lectus. Mauris eget nisl vitae eros vehicula tincidunt quis quis mauris. Nam sed diam nibh. Aenean augue tortor, malesuada in porttitor id, imperdiet vel metus.

            Fusce ultricies hendrerit ipsum, et vulputate magna ultrices blandit. Cras tempus justo dictum, pulvinar velit nec, imperdiet nisi. Integer lobortis imperdiet sapien quis iaculis. Donec id vehicula lorem, eu aliquam ante. Donec sed scelerisque odio. Fusce fringilla dolor sed purus sodales efficitur. Proin sed aliquet ex. Nullam finibus a ipsum a ultricies. Phasellus rhoncus iaculis velit. Integer porttitor mi in velit lacinia, vitae feugiat libero varius. Curabitur condimentum id neque vel pulvinar. Sed gravida, urna sed commodo facilisis, velit tortor volutpat massa, id rutrum nisl risus a justo. Cras vehicula eros in nunc suscipit elementum.

            Nulla mollis, nisi vel varius blandit, ipsum ante rutrum risus, sed volutpat turpis odio sit amet enim. Donec molestie ex viverra felis molestie, nec rutrum erat lacinia. Nullam ut maximus lorem. Nam accumsan aliquam scelerisque. Donec vitae finibus lorem. Proin ligula orci, vehicula nec tellus et, accumsan varius turpis. Donec at nisl tincidunt, dignissim tellus congue, vehicula odio. Donec diam nibh, malesuada vehicula blandit vel, condimentum ac lacus.

            Ut sit amet libero blandit, mattis elit a, suscipit libero. Aliquam a euismod sapien, sit amet dapibus quam. Etiam eleifend facilisis orci, congue vestibulum turpis fringilla eu. Sed feugiat lacus in tristique congue. Donec eu arcu erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nullam pretium metus a leo pharetra tincidunt. Aenean erat sem, aliquet vel pulvinar faucibus, euismod ultrices lectus. Fusce interdum elementum urna, eget posuere felis rhoncus eu. Nulla facilisi. Suspendisse eleifend mauris lectus, nec eleifend urna pretium sed. Pellentesque sagittis nisl id dignissim sollicitudin. 
          </div>  
        </b-col>
      </div>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import jwt from 'jsonwebtoken'

export default {
  name: 'References',
  data() {
    return {
      references: [],
      refFinal: [],
      allRef: [],
      loading: true
    }
  },
  mounted: async function(){
    await this.createJwt()
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}` }
      }
      let vm = this
      axios.get(
        'https://sheets.googleapis.com/v4/spreadsheets/1fUX-SgxLhr08YuwH1RoBG7wqT3mmkrwMAdi1MnX8sGE/values/References!A1:AZ9999', config
        ).then ( (res) => {
      for (let i = 0 ; i < res.data.values.length; i++) {
            if (res.data.values
              && res.data.values[i] != "") {
              const record = res.data.values[i]
              let ref = record[3].match(/[\da-fA-F]{40}/gim)
              if (ref) {
                vm.references = [...new Set([...vm.references, ...ref])]
              }
            }
      }
      this.getCitations()
      vm.loading=false
    })
    .catch((error)=>{
      console.log(error)
      this.loading = false;
    })
  },
  methods : {
    getCitations: function() {
      // eslint-disable-next-line no-useless-escape
      const clean_references = this.references.map( (ref) => {return ref.replace(/[\[\]']+/g, '')})
      if (clean_references.length == 0){
        return
      }
      const payload = {"responseType":"blob","references_ids": clean_references}
      const authToken = process.env.VUE_APP_AUTH_TOKEN
      const config = {
        headers: {
          Authorization: `Token session="${authToken}"`
        }
      }
      const url = 'https://api.iloveevidence.com/v2.1/references/get-from-ids?filetype=citation_json'
      axios.post(url,payload, config)
      .then((res) => {
        this.refFinal = res.data.references
        this.allRef = this.references.map((reference) => {
          const auxRefFinal = this.refFinal.map( (ref) => { return ref.id})
          // eslint-disable-next-line no-useless-escape
          let cleanRef = reference.replace(/[\[\]']+/g, '')
          let index = auxRefFinal.indexOf(cleanRef)
          if (index > -1){
            return {
              id: cleanRef,
              citation: this.refFinal[index].citation
            }
          }
          else {
            return {
              id: cleanRef,
              citation: ''
            }
          }
        })
      })
      },
    async createJwt () {
      const secret =  process.env.VUE_APP_SECRET_KEY
      const date = new Date()
      const seconds = Math.floor(date.getTime() / 1000)
      const expSeconds = seconds + 60 * 60
      const payload = {
        iss: "planilla-love@planilla-love.iam.gserviceaccount.com",
        scope: 'https://www.googleapis.com/auth/drive',
        aud: 'https://oauth2.googleapis.com/token',
        exp: expSeconds,
        iat: seconds
      }
      const token = jwt.sign(payload, secret, { algorithm: 'RS256' })
      const oAuthPayload = {
        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
        assertion: token
      }
      await axios.post('https://oauth2.googleapis.com/token', oAuthPayload)
        .then((res) => { localStorage.setItem('gapi.access_token', res.data.access_token) })
        .catch((err) => { console.log(err) })
      return token
    }
  }
}
</script>

<style>
.reference .list-number {
      color: darkgreen;
    }

.title {
    color: #319394;
    font-weight: bolder;
}
</style>
